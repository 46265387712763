<template>
  <news-table
    :contents="newsList"
    :title="title"
    :is-loading="isLoading"
    @click-item="handleClickItem"
  >
    <pagination
      :request="announcements.get"
      @load="handleLoad"
      @fetch="handleFetch"
    ></pagination>
  </news-table>
</template>

<script>
  import { defineComponent } from 'vue'
  import { mapGetters, mapMutations } from 'vuex'

  import NewsTable from '@/components/ContentTable/NewsTable/index.vue'
  import Pagination from '@/components/Pagination/index.vue'
  import { announcements } from '@/service'

  export default defineComponent({
    components: {
      NewsTable,
      Pagination,
    },
    computed: {
      ...mapGetters('layout', ['getFocusNews']),
    },
    data() {
      return {
        title: '公告欄',
        newsList: [],
        isLoading: false,
        announcements,
      }
    },
    methods: {
      ...mapMutations('layout', ['setAllNavsFcous']),
      handleLoad(isLoading) {
        this.isLoading = isLoading
      },
      handleFetch(data) {
        this.newsList = data
      },
      handleClickItem(item) {
        this.$router.push({
          name: 'AnnouncementDetail',
          params: { id: item.id || 1 },
        })
      },
    },
  })
</script>
