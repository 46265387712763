<template>
  <div class="mt-auto d-flex align-items-center justify-content-center py-3">
    <a-pagination
      v-model="page"
      :total="total"
      :size="10"
      @change="handleChange"
    ></a-pagination>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Pagination',
    props: {
      request: {
        type: Function,
        required: true,
      },
    },
    created() {
      this.handleChange()
    },
    data() {
      return { page: 1, total: 0 }
    },
    methods: {
      handleChange(page = this.page) {
        this.$emit('load', true)

        this.request({ page })
          .then(({ data }) => {
            this.total = data.count
            this.$emit('fetch', data.results)
          })
          .finally(() => this.$emit('load', false))
      },
    },
  })
</script>

<style lang="scss"></style>
