
  import { defineComponent, h } from 'vue'
  import { LoadingOutlined } from '@ant-design/icons-vue'

  import ContentTable from '@/components/ContentTable/index.vue'

  export default defineComponent({
    components: {
      ContentTable,
    },
    data() {
      const indicator = h(LoadingOutlined, {
        style: {
          fontSize: '24px',
        },
        spin: true,
      })

      return {
        indicator,
        selectedYear: '',
      }
    },
    props: {
      title: {
        type: String,
        default: '',
      },
      isShowBorder: {
        type: Boolean,
        default: true,
      },
      isShowFilter: {
        type: Boolean,
        default: false,
      },
      contents: {
        type: Array,
        default: () => [],
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      years: {
        type: Array,
        default: () => [],
      },
      createdAtKey: {
        type: String,
        default: 'created_at',
      },
    },
    methods: {
      handleChangeYear() {
        this.$emit('change-year', this.selectedYear)
      },
    },
  })
